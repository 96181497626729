.policy-header-card {
    background-image: url(./images/policyHeader.png);
}
  
.policy-header-card h1 {
    font-size: 7vw;
    text-align: left;
    padding-left: 3.5vw;
    line-height: 18vw;
}

.policy-back-button {
    display: inline-block;
    font-size: 35px;
    color: #673AB7;
}
  
.policy-container {
    width: 200px;
    height: 250px;
    display: inline-block;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px lightgrey;
}
  
.policy-image {
    width: 200px;
    height: 150px;
    background-color: transparent;
}
  
.policy-image svg {
    margin-top: 40px;
    color: #673AB7;
    font-size: 100px;
}
  
.policy-label {
    padding: 10px;
    width: 180px;
    height: 100px;
    color: black;
    text-align: center;
}
  
.policy-title {
    padding: 0px 10px;
    display: inline-block;
    vertical-align: middle;
}
  
.policy-text {
    display: block;
    text-align: start;
    padding: 0px 50px;
}

.policy-button {
    background-color: transparent;
    border: none;
    font-size: initial;
    vertical-align: middle;
    padding-bottom: 20px;
    cursor: pointer;
}