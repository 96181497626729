.form-area {
    width: 75%;
    max-width: 775px;
    padding: 20px;
}
  
.form-footer {
    opacity: 1;
    transition: opacity 500ms;
}
  
.form-footer-fadeout {
    opacity: 0;
}
  
.form-result {
    font-size: 20px;
}
  
.form-result p {
    margin-block-start: 6px;
    margin-block-end: 6px;
    display: inline-block;
    margin-left: 8px;
}
  
.form-success-icon {
    color: #00E676;
}
  
.form-failed-icon {
    color: #FF5350;
}

.form-failed-message {
    display: block;
    padding-bottom: 0px;
}

.recaptcha-container {
    width: 304px;
    height: 78px;
    margin: auto;
}

@media (orientation: portrait) {
    .form-area {
        width: 87%;
    }
}