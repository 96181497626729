.support-header-card {
    background-image: url(./images/supportHeader.png);
}

.sa-container {
    height: 60vh;
    overflow: hidden;
}
  
.sa-topics {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}
  
.sa-articles {
    display: inline-block;
    width: 70%;
    height: 100%;
}
  
.sa-loading-container {
    display: flex;
    justify-content: center;
    height: 100%;
}
  
.sa-loading {
    margin: auto;
    width: min(90%, 300px);
}
  
.sa-switcher {
    display: none;
}

@media (orientation: portrait) {
    .sa-container {
        position: relative;
    }
  
    .sa-topics {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;
        transition: all 1s;
    }
  
    .sa-topics-closed {
        left: -100%;
    }
  
    .sa-articles {
        position: absolute;
        right: 0px;
        height: 100%;
        width: 90%;
        z-index: 0;
    }
  
    .sa-switcher {
        position: absolute;
        display: block;
        z-index: 2;
        height: 100%;
        width: 8%;
        left: 92%;
        background-color: #673AB7;
        transition: all 1s;
    }
  
    .sa-switcher-closed {
        left: 0px;
    }
  
    .sa-switcher button {
        position: relative;
        margin: auto;
        height: 100%;
        background-color: transparent;
        border: none;
    }
    
    .sa-switcher-line {
        background-color: white;
        height: 25%;
        width: 6.5px;
        border-radius: 5px;
    }
}
@media (min-width: 850px) {
    .sa-body {
        width: 70%;
    }
}