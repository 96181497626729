.cookies-header-card {
    background-image: url(./images/cookieHeader.png);
}
  
#cookie-declaration, #ot-sdk-cookie-policy {
    width: 85%;
    margin: auto;
}
  
.CookieDeclarationTypeHeader {
    font-family: "Fredoka One" !important;
    font-weight: normal !important;
}
  
#cookie-declaration p, th, td {
    font-family: "Orkney", Arial, sans-serif !important;
}
  
#cookie-declaration th, td {
    font-size: 1.2em;
}
  
.CookieDeclarationType {
    box-shadow: 0px 0px 15px 0px darkgrey !important;
    background-color: white !important;
    border-radius: 20px;
    border: none !important;
    padding: 15px !important;
}