.profile-container {
    width: 200px;
    height: 350px;
    padding-right: 20px;
    display: inline-block;
}

.profile-image {
    width: 200px;
    height: 250px !important;
    background-color: #b388ff;
    border-radius: 15px 15px 0px 0px;
    background-size: cover;
}

.profile-label {
    padding: 10px 15px;
    width: 170px;
    height: 60px;
    background-color: white;
    text-align: start;
}