/* Header card */
.home-header-card {
  height: 60vh;
  background-image: url(./images/appHeroImage.png);
}

.home-header-card-left-col {
  width: 40%;
  display: inherit;
  padding: 1vh;
}

.home-header-card-right-col {
  width: 50%;
  display: none;
}

.home-header-card-promo {
  margin: auto;
  margin-bottom: 15px;
  background-color: #673AB7;
  color: white;
  padding: 5px 12px;
  width: max-content;
  font-family: "Fredoka One" !important;
  font-size: 1.2em;
  border-radius: 15px;
}

.home-header-card-link {
  color: black;
  text-decoration: none;
}

/* Features card */
.features-card iframe {
  width: 100%;
  border: none;
  overflow: visible;
  padding-top: 15px;
  height: 510px;
}

/* Content card */
.content-list {
  width: max-content;
}

.content-list a {
  color: black;
}

.content-container {
  display: inline-block;
  text-align: start;
  padding-right: 20px;
  width: 250px;
  vertical-align: top;
}

.content-image {
  height: 150px;
  border-radius: 12px;
  width: 250px;
  object-fit: cover;
}

.podcast-fab {
  position: absolute !important;
  left: 97px !important;
  top: 47px !important;
  background-color: #673ab7 !important;
  box-shadow: 0px 0px 30px 0px white !important;
}

.podcast-fab i {
  font-size: 40px !important;
}

/* Newsletter form card */
.npp-dialog-link {
  background-color: transparent;
  border: none;
  text-align: center;
  display: block;
  font-size: 13px;
}

.npp-dialog-link p {
  margin: 0;
}

/* About us card */
.profiles-list-container {
  width: 100%;
  overflow: auto;
}

.profiles-list {
  width: max-content;
}

@media (orientation: portrait) {
  .home-header-card {
    background-image: none;
    height: auto;
    padding: 0;
    padding-top: 15px;
  }

  .home-header-card-left-col {
    width: calc(100% - 30px);
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-header-card-right-col {
    width: 100%;
    display: block;
  }

  .home-header-card-right-col img {
    width: 100%;
    margin-bottom: -4px;
  }

  .features-showcase {
    width: auto;
    height: auto;
    transform: none;
  }

  .features-container {
    margin: auto;
    display: block;
    height: 165px;
  }
  
  .screenshot-container {
    display: block;
    width: 70%;
    margin: auto;
    background-position: top;
    background-size: cover !important;
    height: 58vh;
  }
}

@media (min-width: 850px) {
  .home-header-card-left-col {
    transform: scale(1.2);
    padding-left: 7vw;
    padding-top: 4vw;
    width: 40vw;
  }
}

@media (min-width: 1150px) {
  .home-header-card-left-col {
    transform: scale(1.4);
    width: 37vw;
    padding-left: 9vw;
    padding-top: 6.5vw;
  }
}

@media (min-width: 1800px) {
  .home-header-card-left-col {
    transform: scale(1.6);
    padding-left: 8vw;
    padding-top: 5vw;
  }
}

@media (max-width: 675px) {
  .features-card iframe {
    height: 600px;
    -webkit-mask-image: linear-gradient(black 90%, transparent);
    mask-image: linear-gradient(black 90%, transparent);
  }
}