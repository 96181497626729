.sa-title {
    display: inline-block;
    align-items: center;
}
  
.sa-title svg {
    display: inline-block;
    font-size: 50px;
    padding-right: 15px;
    color: #673AB7;
}
  
.sa-title-text {
    display: inline-block;
}
  
.sa-headline {
    text-align: left;
    margin-bottom: 0px;
}
.sa-info {
    margin-top: 5px;
    text-align: left;
}
  
.sa-body {
    width: 90%;
    padding: 5px 20px;
    margin: auto;
    text-align: start;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 15px 0px darkgrey;
}