@font-face {
  font-family: "Orkney";
  src: url(./fonts/OrkneyRegular.ttf);
}

@font-face {
  font-family: "Fredoka One";
  src: url(./fonts/FredokaOne-Regular.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

main {
  background-size: cover;
  background-image: url(./images/bg1.jpg);
  overflow: auto;
}

.lev-card {
  margin: 0px 0px 20px 0px;
  padding: 15px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.95);
}

body {
  font-family: "Orkney", Arial, sans-serif;
}

h1, h2 {
  font-family: "Fredoka One";
  font-weight: normal;
}

h2 {
  font-size: 2em;
}

.header-card {
  height: 30vw;
  border-radius: 0 0 20px 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
}

.header-card-title {
  font-size: 10.5vw;
  text-align: left;
  padding-left: 3.5vw;
  line-height: 15vw;
}

.raised-card {
  border-radius: 20px;
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 15px 0px darkgrey;
}

@media (min-width: 850px) {
  .header-card:not(.policy-header-card) header-card-title {
    font-size: 9.5vw;
    text-align: left;
    padding-left: 5vw;
    line-height: 17vw;
  }
}