.footer-card {
    margin: 0;
    border-radius: 20px 20px 0 0;
    text-align: center;
}

.footer-legal {
    margin-bottom: 0px;
}

.footer-card a {
    display: inline-block;
    font-family: 'Orkney';
    font-size: 18px;
}

.footer-separater {
    display: inline-block;
    padding: 0px 5px;
}

.social-logos {
    height: 70px;
    width: 70px;
    padding: 0px 10px;
}